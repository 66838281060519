import * as React from "react"
import { useState, useEffect } from "react"
import { Link } from "gatsby"
import { animateScroll } from "react-scroll"
import * as style from "../styles/common.module.scss"
import FTLogo from "../images/footer-logo.png"

const Footer = () => {
  const [isUnder, setIsUnder] = useState(false)

  const scrollAction = () => {
    if (150 < window.scrollY) {
      setIsUnder(true)
    } else {
      setIsUnder(false)
    }
  }
  useEffect(() => {
    window.addEventListener("scroll", scrollAction)
    return () => window.removeEventListener("scroll", scrollAction)
  }, [])

  return (
    <>
      <div
        onClick={() => animateScroll.scrollToTop(150)}
        onKeyDown={() => animateScroll.scrollToTop(150)}
        role="button"
        tabIndex={0}
        aria-label="ページトップへ"
        className={
          isUnder
            ? `${style.toTop} ${style.toEffect} ${style.canToTop}`
            : `${style.toTop} ${style.toEffect}`
        }
      >
        <div className={`${style.arrowBase} ${style.arrow}`}></div>
      </div>
      <footer>
        <div className={style.footerWrapper}>
          <div className={style.footerProperty}>
            <div className={style.footerLogo}>
              <Link to="/">
                <img src={FTLogo} alt="ARROWS & Company Ltd" />
              </Link>
            </div>
            <h2>株式会社アローズ</h2>
            <p>
              〒151-0073
              <br />
              東京都渋谷区笹塚1-62-3 アルス笹塚ビル1Ｆ
            </p>
            <p>
              TEL:03-5365-3838
              <br />
              FAX:03-5365-3880
            </p>
            <Link to="/contact" className={`${style.btn} ${style.footerBtn}`}>
              お問合せ
            </Link>
          </div>
          <div className={style.footerRight}>
            <div className={style.footerMap}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3241.1814877549746!2d139.66040731525865!3d35.67253298019634!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018f31a8f7f86dd%3A0x5a1885dee9ea079b!2z77yI5qCq77yJ44Ki44Ot44O844K6!5e0!3m2!1sja!2sjp!4v1509417382941"
                title="arrowsマップ"
                className={style.footerIframe}
              ></iframe>
            </div>
          </div>
          <div className={style.footerBottom}>
            <p className={style.navPrivacy}>
              <Link to="/privacypolicy">
                <span>プライバシーポリシー</span>
              </Link>
            </p>
            <p className={style.copyRight}>
              Copyright 2000-2004 Arrows co. Ltd All Rights Reserved.
            </p>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
