import * as React from "react"
import { useState, useEffect } from "react"
import { Link } from "gatsby"
import Hamburger from "hamburger-react"
import { StaticImage } from "gatsby-plugin-image"
import * as style from "../styles/common.module.scss"
import HDLogo from "../images/logo.png"

const Header = () => {
  const [isOpen, setOpen] = useState(false)
  const [isHeightOver, setIsHeightOver] = useState(false)
  useEffect(() => {
    const scrollAction = () => {
      if (150 > window.scrollY) {
        setIsHeightOver(true)
      } else {
        setIsHeightOver(false)
      }
    }
    window.addEventListener("scroll", scrollAction, {
      capture: false,
      passive: true,
    })
    scrollAction()
    return () => {
      window.removeEventListener("scroll", scrollAction)
    }
  }, [])
  return (
    <>
      <header>
        <div
          className={
            isHeightOver
              ? style.headerWrapper
              : `${style.headerWrapper} ${style.canScroll}`
          }
        >
          <div className={style.logo}>
            <Link to="/">
              <img src={HDLogo} alt="ARROWS & Company Ltd" />
            </Link>
          </div>
          <ul className={style.headerNavPc}>
            <li>
              <Link to="/mind">
                <span>MIND</span>
              </Link>
            </li>
            <li>
              <Link to="/service">
                <span>SERVICE</span>
              </Link>
            </li>
            <li>
              <Link to="/about">
                <span>ABOUT</span>
              </Link>
            </li>
            <li className={style.openRecruitMenu}>
              <Link to="/recruit">
                <span>RECRUIT</span>
              </Link>
              <nav className={style.headerRecruit}>
                <div className={style.headerRecruitInner}>
                  <div className={style.content}>
                    <div className={style.contentImg}>
                      <StaticImage
                        src="../images/header-recruit-img.png"
                        alt="RECRUIT"
                        quality={90}
                        placeholder="dominantColor"
                        formats={["AUTO", "WEBP", "AVIF"]}
                        layout="fixed"
                        width={220}
                        height={220}
                      />
                    </div>
                    <div className={style.contentNav}>
                      <h3>RECRUIT</h3>
                      <ul>
                        <li>
                          <Link to="/recruit">採用について</Link>
                        </li>
                        <li>
                          <Link to="/recruit/work">職種紹介</Link>
                        </li>
                        <li>
                          <Link to="/recruit/interview">社員インタビュー</Link>
                        </li>
                        <li>
                          <Link to="/recruit/arrowsdata">ARROWS DATA</Link>
                        </li>
                        <li>
                          <Link to="/recruit/faq">Q&A</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className={style.footer}>
                    <span>
                      アローズの募集要項・エントリーは<Link to="/">こちら</Link>
                    </span>
                    <Link to="/" className={style.noLine}>
                      <StaticImage
                        src="../images/header-recruit-bnr01.png"
                        alt="リクナビ"
                        quality={90}
                        placeholder="dominantColor"
                        formats={["AUTO", "WEBP", "AVIF"]}
                        layout="fixed"
                        width={160}
                        height={50}
                      />
                    </Link>
                    <Link to="/" className={style.noLine} target="_blanks">
                      <StaticImage
                        src="../images/header-recruit-bnr02.png"
                        alt="マイナビ"
                        quality={90}
                        placeholder="dominantColor"
                        formats={["AUTO", "WEBP", "AVIF"]}
                        layout="fixed"
                        width={160}
                        height={50}
                      />
                    </Link>
                  </div>
                </div>
              </nav>
            </li>
            <li>
              <Link to="/contact">
                <span>CONTACT</span>
              </Link>
            </li>
          </ul>
          <div className={style.headerMenuSp}>
            <Hamburger
              toggled={isOpen}
              toggle={setOpen}
              size={20}
              distance="sm"
              color="#212121"
              easing="ease-in"
              duration={0.2}
            />
          </div>
        </div>
      </header>
      <div
        className={
          isOpen ? `${style.headerNavSp} ${style.open}` : style.headerNavSp
        }
      >
        <ul>
          <li>
            <Link to="/mind">
              <span>MIND</span>
            </Link>
          </li>
          <li>
            <Link to="/service">
              <span>SERVICE</span>
            </Link>
          </li>
          <li>
            <Link to="/about">
              <span>ABOUT</span>
            </Link>
          </li>
          <li>
            <Link to="/recruit">
              <span className={style.toggleIcon}>RECRUIT</span>
            </Link>
          </li>
          <li>
            <Link to="/recruit">
              <span className={style.recruitLink}>採用について</span>
            </Link>
          </li>
          <li>
            <Link to="/recruit/work">
              <span className={style.recruitLink}>職種紹介</span>
            </Link>
          </li>
          <li>
            <Link to="/recruit/interview">
              <span className={style.recruitLink}>
                社員インタビュー（企画・営業）
              </span>
            </Link>
          </li>
          <li>
            <Link to="/recruit/interview">
              <span className={style.recruitLink}>
                社員インタビュー（総務・管理）
              </span>
            </Link>
          </li>
          <li>
            <Link to="/recruit/interview">
              <span className={style.recruitLink}>
                社員インタビュー（商品開発）
              </span>
            </Link>
          </li>
          <li>
            <Link to="/recruit/arrowsdata">
              <span className={style.recruitLink}>ARROWS DATA</span>
            </Link>
          </li>
          <li>
            <Link to="/recruit/faq">
              <span className={style.recruitLink}>Q&A</span>
            </Link>
          </li>
          <li>
            <Link to="/contact">
              <span>CONTACT</span>
            </Link>
          </li>
        </ul>
      </div>
    </>
  )
}

export default Header
