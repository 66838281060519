import * as React from "react"
import Header from "./header"
import Footer from "./footer"
import "modern-css-reset"
import "../styles/fonts.scss"
import "../styles/common.scss"

const Layout = props => {
  return (
    <>
      <Header />
      <main>{props.children}</main>
      <Footer />
    </>
  )
}

export default Layout
